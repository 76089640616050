body {
  margin: 0;
  padding: 0;
}

#fireworks-template {
  display: none;
}

#fireContainer {
  z-index: 706;
  position: absolute;
  top: 0;
  left: 0;
}

.firework {
  width: 4px;
  height: 4px;
  background: #666;
  border: 1px solid #333;
  font-size: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

.fireworkParticle {
  width: 10px;
  height: 10px;
  font-size: 1px;
  position: absolute;
  overflow: hidden;
}

.fireworkParticle img {
  width: 100px;
  display: block;
}

#sound {
  position: absolute;
  top: -706px;
  left: -706px;
}

#debug {
  width: 220px;
  height: 165px;
  color: #666;
  display: none;
  position: absolute;
  top: 8px;
  left: 8px;
  overflow: hidden;
}

/*# sourceMappingURL=index.74f42f5b.css.map */
